import React, { Component } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer
} from "react-google-maps";

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = { directions: null };
  }
  center = {
    lat: -16.39877,
    lng: -71.536706,
  };
  componentDidMount() {
    const { markers } = this.props;
    
    const waypoints = markers.map(p =>({
        location: {lat: p.latitude, lng:p.longitude},
        stopover: true
    }))
    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;  
    

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints: waypoints
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result
          });
        } else {
            console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  render() {
    const { markers } = this.props;
    if (this.state.directions != null) {
      console.log("renderdir");
      console.log(this.state.directions);

      return (
        <GoogleMap defaultZoom={14} center={this.center}>
          {markers.map((marker) => (
          <Marker
            key={marker.id}
            position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude)}}
          />
        ))}
          {this.state.directions && (
            <DirectionsRenderer directions={this.state.directions} />
          )}
        </GoogleMap>
      );
    } else {
      console.log("rendernodirec");
      return (
        <GoogleMap defaultZoom={14} center={this.center}>
          {markers.map((marker) => (
          <Marker
            key={marker.id}
            position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude)}}
          />
        ))}
        </GoogleMap>
      );
    }
  }
}

export default withScriptjs(withGoogleMap(Map));