import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles, useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { firestore } from "../../firebase";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Toolbar from "@material-ui/core/Toolbar";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FilterListIcon from "@material-ui/icons/FilterList";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import ClearIcon from "@material-ui/icons/Clear";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Map from "../Map";
const styles = (theme) => ({
  root: { margin: theme.spacing(2), textAlign: "center" },
  progress: { margin: theme.spacing(2) },
  search: { marginLeft: theme.spacing(2) },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  card: { margin: theme.spacing(2) },
  pagina: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
const columns = [
  { name: "Nombre", numeric: false, disablePadding: true, label: "Nombre" },
  {
    name: "Descripcion",
    numeric: false,
    disablePadding: true,
    label: "Descripcion",
  },
  { name: "Distrito", numeric: false, disablePadding: true, label: "Distrito" },
  { name: "Cantidad", numeric: true, disablePadding: true, label: "Cantidad" },
  { name: "Orden", numeric: true, disablePadding: true, label: "Orden " },
];
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));
const MaybeLoading = withStyles(styles)(({ classes, loading }) =>
  loading ? <CircularProgress className={classes.progress} /> : null
);
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.name}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, verClick } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} seleccionados
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Puntos comunes
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Ver en mapa">
          <IconButton aria-label="mapa" onClick={verClick}>
            <TrendingUpIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filtrar">
          <IconButton aria-label="Filtrar">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.pagina}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default function MaterialUi(props) {
  const classes = useStyles();
  const [distrito, setDistrito] = useState("");
  const [openD, setOpenD] = useState(false);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageU, setPageU] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowsPerPageU, setRowsPerPageU] = useState(10);
  const [markers, setMarkers] = useState([]);
  const places = [
    {latitude: 25.8103146,longitude: -80.1751609},
    {latitude: 27.9947147,longitude: -82.5943645},
    {latitude: 28.4813018,longitude: -81.4387899}
  ];
  const googleMapsApiKey = "AIzaSyD8EwB9-g__lbbfJyWo0JK2P08p2CI1i0I";
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleClickVer = () => {
    setMarkers(
      selected.reduce((total, row) => {
        total.push(rows.find((x) => x.id === row));
        return total;
      }, [])
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePageU = (event, newPage) => {
    setPageU(newPage);
  };

  const handleChangeRowsPerPageU = (event) => {
    setRowsPerPageU(parseInt(event.target.value, 10));
    setPageU(0);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const isOrder = (id) => selected.indexOf(id);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const emptyItems =
    rowsPerPageU - Math.min(rowsPerPageU, items.length - page * rowsPerPageU);
  const onSearchChange = (e) => {
    setSearch(e.target.value.toUpperCase());
  };

  const deSelect = () => {
    const des = [];
    rows.forEach((el) => des.push({ ...el, selected: false, orden: 0 }));
    setRows(des);
  };
 const handleClearSearch = () => {
  if(search) setSearch("")
 }
  const handleChangeD = (event) => {
    setDistrito(event.target.value);
  };

  const handleCloseD = () => {
    setOpenD(false);
  };

  const handleOpenD = () => {
    setOpenD(true);
  };
  let temporal = [];
  const sumpoint = (point) =>
    temporal.filter((row) => row.point === point).length;
  const fetchRows = () => {
    if (distrito) {
      firestore
        .collection("points")
        .where("Distrito", "==", distrito)
        .get()
        .then(
          (snapShots) => {
            setRows(
              snapShots.docs
                .map((doc) => {
                  return {
                    id: doc.id,
                    Descripción: doc.data().Descripción,
                    Distrito: doc.data().Distrito,
                    latitude: parseFloat(doc.data().lat),
                    longitude: parseFloat(doc.data().lng),
                    number: sumpoint(doc.data().point),
                    orden: 0,
                    point: doc.data().point,
                  };
                })
                .concat({
                  id: "1001",
                  Descripción: "no estan agrupados",
                  Distrito: distrito,
                  lat: -16.39877,
                  lng: -71.536706,
                  number: sumpoint(undefined),
                  point: undefined,
                })
            );
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };
  const fetchData = (distrito) => {
    firestore
      .collection("table")
      .where("Distrito", "==", distrito)
      .get()
      .then(
        (snapShots) => {
          setItems(
            (temporal = snapShots.docs.map((doc) => {
              return {
                cui: doc.id,
                ApellNombres: doc.data().ApellNombres,
                Celular: doc.data().Celular,
                Distrito: doc.data().Distrito,
                Dirección: doc.data().Dirección,
                lat: doc.data().lat,
                lng: doc.data().lng,
                point: doc.data().point,
              };
            }))
          );
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      )
      .then(fetchRows());
  };
  useEffect(() => {
    if (distrito) {
      fetchData(distrito);
    } else {
      setItems([]);
      setRows([]);
    }
  }, [distrito]);
  console.log('render');
  
  return (
    <div className={classes.root}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Distrito</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              {`Se encontraron : (${items.length}) Usuarios y (${rows.length}) Grupos`}
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Grid
            container
            direction="column"
            justify="flex-end"
            alignItems="stretch"
          >
            <Grid container direction="row" justify="space-around">
              <Grid item>
                <Button className={classes.button} onClick={handleOpenD}>
                  Distritos
                </Button>
                <FormControl className={classes.formControl}>
                  <InputLabel id="Distrito">Distrito</InputLabel>
                  <Select
                    labelId="Distrito"
                    id="Distrito"
                    open={openD}
                    onClose={handleCloseD}
                    onOpen={handleOpenD}
                    value={distrito}
                    onChange={handleChangeD}
                  >
                    <MenuItem value="">
                      <em>Ninguno</em>
                    </MenuItem>
                    <MenuItem value="Cayma">Cayma</MenuItem>
                    <MenuItem value="C.colorado">C.colorado</MenuItem>
                    <MenuItem value="Paucarpata">Paucarpata</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Divider />
            <Grid item className={clsx(classes.column, classes.helper)}>
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <EnhancedTableToolbar
                    verClick={handleClickVer}
                    numSelected={selected.length}
                  />
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        rowCount={rows.length}
                      />
                      <TableBody>
                        {rows
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const ord = isOrder(row.id) + 1;
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.id)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row.point}
                                </TableCell>
                                <TableCell align="left">
                                  {row.Descripción}
                                </TableCell>
                                <TableCell align="left">
                                  {row.Distrito}
                                </TableCell>
                                <TableCell align="right">
                                  {row.number}
                                </TableCell>
                                <TableCell align="right">
                                  {ord > 0 ? ord : "-"}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{ height: (dense ? 5 : 10) * emptyRows }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
                <FormControlLabel
                  control={
                    <Switch checked={dense} onChange={handleChangeDense} />
                  }
                  label="Densa"
                />
              </div>
            </Grid>
            <Grid item>
              <TextField
                value={search}
                onChange={onSearchChange}
                className={classes.search}
                id="input-search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton aria-label="limpiar"  onClick={handleClearSearch}>
                <ClearIcon />
              </IconButton>
              <Paper className={classes.paper}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombres</TableCell>
                        <TableCell>Telefono</TableCell>
                        <TableCell>Distrito</TableCell>
                        <TableCell>Direccion</TableCell>
                        <TableCell>Ver mapa</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPageU > 0
                        ? items
                            .filter(
                              (item) =>
                                !search || item.ApellNombres.includes(search)
                            )
                            .slice(
                              pageU * rowsPerPageU,
                              pageU * rowsPerPageU + rowsPerPageU
                            )
                        : items.filter(
                            (item) =>
                              !search || item.ApellNombres.includes(search)
                          )
                      ).map((item) => {
                        return (
                          <TableRow key={item.cui}>
                            <TableCell style={{ width: 160 }}>
                              {item.ApellNombres}
                            </TableCell>
                            <TableCell style={{ width: 100 }}>
                              {item.Celular}
                            </TableCell>
                            <TableCell style={{ width: 100 }}>
                              {item.Distrito}
                            </TableCell>
                            <TableCell>{item.Dirección}</TableCell>
                            <TableCell>
                              <Button>Ver</Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyItems > 0 && (
                        <TableRow style={{ height: 10 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            10,
                            25,
                            50,
                            { label: "All", value: -1 },
                          ]}
                          colSpan={5}
                          count={items.length}
                          rowsPerPage={rowsPerPageU}
                          page={pageU}
                          onChangePage={handleChangePageU}
                          onChangeRowsPerPage={handleChangeRowsPerPageU}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                  <MaybeLoading loading={loading} />
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>

        <Divider />
        <ExpansionPanelActions>
          <Button size="small">Cancel</Button>
          <Button size="small" color="primary">
            Save
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
      {markers.length>0?<Map
      googleMapURL={
        'https://maps.googleapis.com/maps/api/js?key=' +
        googleMapsApiKey +
        '&libraries=geometry,drawing,places'
      }

      markers={markers}
      loadingElement={<div style={{height: `100%`}}/>}
      containerElement={<div style={{height: "80vh"}}/>}
      mapElement={<div style={{height: `100%`}}/>}
      defaultZoom={14}
    />:null}
    </div>
  );
}
