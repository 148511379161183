import React from "react";
import { Facebook as FacebookIcon } from "mdi-material-ui";
import { Google as GoogleIcon } from "mdi-material-ui";
import { Yahoo as YahooIcon } from "mdi-material-ui";

const authProviders = [
  {
    providerId: "facebook.com",
    color: "#3c5a99",
    icon: <FacebookIcon />,
    name: "Facebook",
  },
  {
    providerId: "google.com",
    color: "#4285f4",
    icon: <GoogleIcon />,
    name: "Google",
  },
  {
    providerId: "yahoo.com",
    color: "#410093",
    icon: <YahooIcon />,
    name: "Yahoo",
  },
];

export default authProviders;
