import React, { useEffect, useState, useRef } from "react";
import Loader from "../Loader";
import CustomToolbarSelect from "../CustomToolbarSelect";
import { firestore } from '../../firebase'
import MUIDataTable from "mui-datatables";
import {  Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Mps from "../Mps";
const useStyles = makeStyles(() =>({
  grid: {
    margin: 0,
    width: "100%",
  },
  point: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
}));
function MyTable () {     
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);
 const [items, setItems] = useState([]);
 const columns = [
          {
          name: "cui",
          label: "CUI",
          options: {
          filter: false,
          sort: true,
          }
          },
          {
          name: "ApellNombres",
          label: "ApellNombres",
          options: {
          filter: false,
          sort: false,
          }
          },
          {
          name: "Celular",
          label: "Celular",
          options: {
          filter: false,
          sort: false
          }
          },
          {
          name: "Dirección",
          label: "Dirección",
          options: {
          filter: false,
          sort: false,
          }
          },
          {
          name: "Mapa",
          label: "Mapa",
          options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button onClick={()=>{
               var keys = ['cui', 'ApellNombres', 'Celular','Dirección','none','lat','lng'];
              var result = {};
              keys.forEach((key, i) => result[key] = (tableMeta.rowData[i])?tableMeta.rowData[i]:'none');
             
              handleClick(result);
              }}>
                Ver
              </Button>
            );
            }
          }
          },
          {
          name: "lat",
          label: "lat",
          options: {
          filter: false,
          sort: false,
          viewColumns: false,
          }
          },
          {
          name: "lng",
          label: "lng",
          options: {
          filter: false,
          sort: false,
          viewColumns: true,
          }
          },
          {
            name: "guardar",
            label: "Guardar",
            options: {
            filter: false,
            sort: false,
            viewColumns: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Button onClick={()=>{
                 var keys = ['cui', 'ApellNombres', 'Celular','Dirección','none','lat','lng'];
                var result = {};
                keys.forEach((key, i) => result[key] = (tableMeta.rowData[i])?tableMeta.rowData[i]:'none');
               
                handleGuarda(result);
                }}>
                  Guardar
                </Button>
              );
              }
            }
          }
      ];
  const options={
          filterType: 'checkbox',          
          customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect vermapa={vermapa} selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />
          ),
      };
const vermapa=(a)=>{
  var keys = ['cui', 'ApellNombres', 'Celular','Dirección','none','lat','lng'];
    
    const nextSelectedRows = a.reduce((nextSelectedRows, curr, index) => {
      var result = {};
      keys.forEach((key, i) => result[key] = (typeof curr[i] === 'string')?curr[i]:'none');
      nextSelectedRows.push(result);
      return nextSelectedRows;
    }, []);
  ref.current.listado(nextSelectedRows);
};
  const handleClick = (a) => {        
    ref.current.showToast(a);
  };
  const handleGuarda = (a) => {       
    ref.current.showGuarda(a.cui);
  };
const classes = useStyles();
const dataRows= ()=>{
  firestore.collection("table").get().then((snapShots)=>{
    setItems(snapShots.docs.map(doc =>{
            return {cui:doc.id,ApellNombres:doc.data().ApellNombres,Celular:doc.data().Celular,Distrito:doc.data().Distrito,Dirección:doc.data().Dirección,lat:doc.data().lat,lng:doc.data().lng}            
        }))   
  },error=>{
    console.log(error);       
  })
}
useEffect(() => {
  dataRows();  
  setLoading(false);
}, [])

//console.log("items body fun :"+ JSON.stringify(items));
if (loading) {
  return <Loader />;
}
return(
  <Grid className={classes.grid} container justify="center" spacing={1}> 
  <Grid item xs={12}>
    <MUIDataTable
          title={"Lista de alumnos"}
          data={items}
          columns={columns}
          options={options}
          />  
    </Grid>    
    <Grid item xs={12}>
    <Mps ref={ref}/>  
    </Grid>    
  </Grid>             
  )  
}
export default MyTable;