import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import MaterialUi from "../MaterialUi";
import { Grid } from "@material-ui/core";
const styles = (theme) => ({
  grid: {
    margin: 0,
    width: "100%",
  },
});
class AdminPage extends Component {
  render() {
    const {classes} = this.props;
    return (
      <Grid className={classes.grid} container  spacing={1}>
        <MaterialUi />
      </Grid>
    );
  }
}

export default withStyles(styles)(AdminPage);
