import React, {forwardRef, useImperativeHandle} from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AdjustIcon from '@material-ui/icons/Adjust';
import mapStyles from "../mapStyles";
import { formatRelative } from "date-fns";
import authentication from "../../services/authentication";
const useStyles = makeStyles((theme)=>({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(20),
    right: theme.spacing(2),
  },
}));
const libraries = ["places"];
const mapContainerStyle = {
  height: "100vh",
  width: "100vw",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
const center = {
  lat: -16.398770,
  lng: -71.536706,
};

  const Mps = forwardRef((props, ref) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyD8EwB9-g__lbbfJyWo0JK2P08p2CI1i0I',
    libraries,
  });
  const [markers, setMarkers] = React.useState([]);
  const [markerMap, setMarkerMap] = React.useState([]);
  const [point,setPoint] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const onMapClick = React.useCallback((e) => {
    setPoint(null)
    setMarkerMap(() => [
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);
  }, []);
  const showToast = (a) => {
    setSelected(a)
    
  };
  const listado=(a)=>{
    setMarkers(a);
    //console.log(JSON.stringify(a));
    
  }
  const showGuarda = (a) => {
    let b=`${point.lat}`;
    let c=`${point.lng}`;
    guarda(a,b,c)
  };
  const guarda = (point, lat, lng)=>{
    authentication
    .setPoint(point, lat, lng)
    .then(() => {
      //console.log("guardado");
      
    })
    .catch((reason) => {
      const code = reason.code;
      const message = reason.message;

     console.log(code+message);
     
    })
    .finally(() => {
     //console.log("terminando");
     
    });
  }
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []); 
  useImperativeHandle(ref, () => {
    return {
      showToast: showToast,
      showGuarda: showGuarda,
      listado: listado
    };
  });
  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";  
  return (
    <div className="mapa">
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
      <Locate panTo={panTo} />
        {markers.map((marker) => (
          <Marker
            key={marker.cui}
            position={{ lat: parseFloat(marker.lat), lng: parseFloat(marker.lng)}}
            onClick={() => {
              setSelected(marker);
            }}
            icon={{
              url: `/bear.svg`,
              origin: new window.google.maps.Point(0,0),
              anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
        ))}
        {markerMap.map((marker) => (
          <Marker
            key={`${marker.lat}-${marker.lng}`}
            position={{ lat: parseFloat(marker.lat), lng: parseFloat(marker.lng)}}
            onClick={() => {
              setPoint(marker);
            }}
            icon={{
              url: `/bear2.svg`,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
        ))}
        {selected ? (
          <InfoWindow
            position={{ lat: parseFloat(selected.lat), lng: parseFloat(selected.lng) }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div>
              <h2>
                <span role="img" aria-label="bear">
                  🐻
                </span>
                {selected.ApellNombres}
              </h2> {"Celular: "}{selected.Celular} {"- cui: "}{selected.cui}:
              <p>"Dirección: "{selected.Dirección}</p>
            </div>
          </InfoWindow>
        ) : null}
        {point ? (
          <InfoWindow
            position={{ lat: parseFloat(point.lat), lng: parseFloat(point.lng) }}
            onCloseClick={() => {
              setPoint(null);
            }}
          >
            <div>
              <h2>
                <span role="img" aria-label="bear">
                  🐻
                </span>
                Punto de asignacion
              </h2>{point.lat} {" - "}{point.lng}:
              <p>hora: {formatRelative(point.time, new Date())}</p>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  );
})
function Locate({ panTo }) {
  
  const classes = useStyles();
  return (
    <Fab aria-label="add" className={classes.point}
    onClick={() => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => null
      );
    }}>
        <AdjustIcon/>
      </Fab>
    
  );
}
export default Mps;
